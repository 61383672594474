@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

* {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	box-sizing: border-box;
	list-style: none;
	text-decoration: none;
	/* border: 1px solid rgb(255, 0, 0, 0.2); */
}

:root {
	--color-bg: #333333;
	--color-bg-variant: #111111;
	--color-primary: rgb(211, 189, 46);
	--color-primary-gradient: linear-gradient(
		90deg,
		rgba(254, 216, 0, 0) 0%,
		rgba(236, 205, 19, 0.21) 65%,
		rgba(211, 189, 46, 1) 100%
	);
	--color-primary-variant: rgba(148, 132, 46, 0.4);
	--colort-withe: #ffffff;
	--color-light: rgba(255, 255, 255, 0.6);

	--transition: all 400ms ease;

	--container-width-lg: 60%;
	--container-width-md: 86%;
	--container-width-ms: 90%;
}

html {
	overflow-x: hidden;
	scroll-padding-top: 6rem;
	scroll-behavior: smooth;
}

::-webkit-scrollbar {
	display: none;
}

body {
	min-height: 100hv;

	font-family: "Poppins", sans-serif;
	color: var(--colort-withe);
	line-height: 1.7;

	background: var(--color-bg) no-repeat;
	background-image: url(../src/assets/img/BACKGROUND.svg);
	background-position: center;
	background-size: cover;
}

/* GENERAL STRYLES */
.container {
	position: relative;
	width: var(--container-width-lg);
	margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5 {
	font-weight: 500;
}

h1 {
	font-size: 2.5rem;
}

h2 {
	margin-bottom: 0;
}

h5 {
	margin-top: 0rem;
}

section {
	margin-top: 8rem;
}

section > h2,
section > h5 {
	text-align: center;
	color: var(--color-primary);
	margin-bottom: 3rem;
}

.text-light {
	color: var(--color-light);
	transition: var(--transition);
}

a {
	color: var(--colort-withe);
}

a:hover {
	color: var(--colort-withe);
}

.btn {
	width: max-content;
	display: inline-block;
	color: var(--color-primary);
	padding: 0.75rem 1.2rem;
	border-radius: 0.4rem;
	cursor: pointer;
	border: 1px solid var(--color-primary);
	transition: var(--transition);
}

.btn:hover {
	background: var(--colort-withe);
	color: var(--color-bg);
	border-color: transparent;
}

.btn-primary {
	background: var(--color-primary);
	color: var(--color-bg);
}

img {
	display: block;
	width: 100%;
	object-fit: cover;
}

/* MEDIA QUERIES (MEDIUM DEVICES) */
@media screen and (max-width: 1024px) {
	.container {
		width: var(--container-width-md);
	}

	section {
		margin-top: 6rem;
	}
}

/* MEDIA QUERIES (SMALL DEVICES) */
@media screen and (max-width: 600px) {
	.container {
		width: var(--container-width-ms);
	}

	section > h2 {
		margin-bottom: 2rem;
	}
}
