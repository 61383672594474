.container.container__header {
	height: 100vh;
	position: relative;
	justify-content: center;
}

.container.container__header .image__header {
	width: 50vw;
	min-height: 70%;
	filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
	position: absolute;
	top: 50%;
	left: 50%;
	margin-right: -50%;
	transform: translate(-50%, -50%);
}

.container.container__header .image__header img {
	opacity: 0.5;

	position: absolute;
	top: 50%;
	left: 50%;
	margin-right: -50%;
	transform: translate(-50%, -50%);
}

.container.container__header .header__socials {
	width: 100%;
	border-radius: 1rem;
	transform: translate(-50%, -10%);
	left: 50%;
	top: 3rem;
	position: relative;
	display: flex;
	justify-content: center;
}

.container.container__header .action_button__header {
	position: absolute;
	bottom: 1rem;
	width: 100%;
	border-radius: 1rem;
	background: var(--color-primary);
	padding: 8px;
	left: 50%;
	transform: translate(-50%, -10%);

	text-decoration: none;
	text-align: center;
	font-size: 1.2rem;
	font-weight: bold;
	opacity: 0.8;
	color: var(--color-bg);
}

.container.container__header .action_button__header:hover {
	opacity: 1;
}

header video {
	position: absolute;
	padding: 0;
	height: 100%;
	width: 100%;
	object-fit: cover;
	opacity: 0.25;
	z-index: -1;
}

@media screen and (max-width: 1024px) {
	.container.container__header {
		width: var(--container-width-md);
	}

	.container.container__header .image__header {
		width: 100%;
		min-height: 60%;
	}
}

@media screen and (max-width: 600px) {
	.container.container__header {
		width: var(--container-width-ms);
	}

	.container.container__header .image__header {
		width: 100%;
		min-height: 40%;
	}
}
