.service {
	background: var(--color-bg-variant);
	border-radius: 1.2rem;
	border: none;
	height: 100%;
	transition: var(--transition);
	min-height: 100%;
}

/* .service:hover {
	background: transparent;
	border-color: var(--color-primary-variant);
	cursor: default;
} */

.service__head {
	background: var(--color-primary);
	padding: 2rem;
	border-radius: 1.2rem 1.2rem 0 0;
	box-shadow: 0 2rem 1rem rgba(0, 0, 0, 0.1);
}

.service__head h3 {
	color: var(--color-bg);
	font-size: 1rem;
	text-align: center;
	font-weight: bold;
}

.service__list {
	padding: 2rem;
}

.service__list li {
	display: flex;
	gap: 1rem;
	margin-bottom: 0.8rem;
}

.service__list-icon {
	color: var(--color-primary);
	margin-top: 2px;
}

.service__list p {
	font-size: 0.9rem;
}

/* MEDIA QUERIES */
/* MEDIA QUERIES (MEDIUM DEVICES) */
@media screen and (max-width: 1024px) {
	.service {
		height: 100%;
	}
}
