.container.container__team {
	width: var(--container-width-lg);
	padding-bottom: 4rem;
	z-index: -1;
}

#team {
	position: relative;
	z-index: -1;
}

.container__team {
	padding-bottom: 4rem;
	z-index: -1;
}

.hover-layer {
	position: absolute;
	top: 25%;
	left: 0;
	width: 100%;
	height: 50%;
	background-color: rgba(0, 0, 0, 0.9);
	box-shadow: 0px 100px 40px rgba(0, 0, 0, 0.9),
		0px -70px 50px rgba(0, 0, 0, 0.9);
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 30px;
	font-weight: bold;
	color: #333;
	z-index: 1;
}

/* MEDIA QUERIES */
/* MEDIA QUERIES (MEDIUM DEVICES) */
@media screen and (max-width: 1024px) {
	.container.container__team {
		width: var(--container-width-md);
	}
}

/* MEDIA QUERIES (SMALL DEVICES) */
@media screen and (max-width: 600px) {
	.container.container__team {
		width: var(--container-width-ms);
	}
}
