.card {
	height: fit-content;
	background: transparent;
	border-radius: 1.2rem;
	transition: var(--transition);
	border: none;
	min-height: 300px;
	min-width: 200px;
}

.card__front,
.card__back {
	width: 100%;
	height: 100%;
	min-height: 300px;
	background-color: var(--color-bg);
	border-radius: 1.2rem;
	display: block;
	box-shadow: 0.3rem 0.3rem 0.2rem rgba(58, 67, 1, 0.25);
	transition: all 1.75s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.card__front {
	position: relative;
	display: inline-block;
	text-align: center;
	background-position: center;
	background-size: cover;
	transform: rotateY(0);
}

.card .card__front .image img {
	min-height: 300px;
	position: relative;
}

.card__name {
	position: absolute;
	bottom: 30px;
	right: 0px;
	padding: 0px 10px;
	background: var(--color-primary-gradient);
}

.card__back {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px;
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	transform: rotateY(-180deg);
}

/* .card:hover .card__front {
	transform: rotateY(180deg);
	box-shadow: none;
} */

/* .card:hover .card__back {
	opacity: 1;
	transform: rotateY(0);
} */
