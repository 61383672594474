.header__socials .icon__socials {
	display: inline-block;
	margin: auto;
	background: transparent;
	border-radius: 50%;
	color: var(--color-light);
	opacity: 0.7;
}

.header__socials .icon__socials:first-child {
	margin-left: 0%;
}

.header__socials .icon__socials:last-child {
	margin-right: 0%;
}

.header__socials .icon__socials:hover {
	opacity: 1;
}

.header__socials .icon__socials img {
	height: 50px;
	width: 50px;
	border-radius: 50%;
	vertical-align: middle;
}
