.container.contact__container {
	width: var(--container-width-lg);
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
	gap: 12%;
}

.contact__options {
	display: flex;
	flex-direction: column;
	gap: 1.2rem;
}
.contact__options a {
	text-decoration: none;
}

.contact__option {
	background: var(--color-bg-variant);
	padding: 1.2rem;
	border-radius: 1.2rem;
	text-align: center;
	border: 1px solid transparent;
	transition: var(--transition);
}

.contact__option:last-child {
	padding: 1.2rem 0 0 0;
}

.contact__option p {
	font-style: italic;
}

.option__map {
	width: 100%;
	height: 350px;
	border-radius: 0 0 1.2rem 1.2rem;
	margin-bottom: 0;
}

.contact__option:hover {
	background: transparent;
	border-color: var(--color-primary-variant);
}

.contact__option-icon {
	font-size: 1.5rem;
	margin-bottom: 0.5rem;
}

/* FORM */
form {
	display: flex;
	flex-direction: column;
	gap: 1.2rem;
}

input,
textarea {
	width: 100%;
	padding: 1.5rem;
	border-radius: 0.5rem;
	background: rgba(77, 196, 255, 0.4);
	border: 2px solid var(--color-primary-variant);
	resize: none;
	color: var(--color-white);
}

/* MEDIA QUERIES */
/* MEDIA QUERIES (MEDIUM DEVICES) */
@media screen and (max-width: 1024px) {
	.container.contact__container {
		width: var(--container-width-md);
		grid-template-columns: 1fr;
		gap: 2rem;
	}
}

/* MEDIA QUERIES (SMALL DEVICES) */
@media screen and (max-width: 600px) {
	.container.contact__container {
		width: var(--container-width-ms);
	}
}
