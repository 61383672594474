.container {
	width: 100%;
}

.container.container__services {
	width: var(--container-width-lg);
	padding-bottom: 2rem;
}

/* MEDIA QUERIES */
/* MEDIA QUERIES (MEDIUM DEVICES) */
@media screen and (max-width: 1024px) {
	.container.container__services {
		width: var(--container-width-md);
	}
}

/* MEDIA QUERIES (SMALL DEVICES) */
@media screen and (max-width: 600px) {
	.container.container__services {
		width: var(--container-width-ms);
	}
}
